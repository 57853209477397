import { LoadingButton } from "@mui/lab";
import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useAxios } from "../../hooks";
import pages from "./../../constants/pages";
import convertDate from "./../../utils/convertDate";

const OuterBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 75vh;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 17px;
  box-sizing: border-box;
  background: #ffffff;
`;

const OuterPaper = styled(Box)`
  width: 100%;
  border-radius: 15px;
  min-height: 50vh;
  background: rgba(255, 255, 255, 0.5);
  align-content: center;
`;

const CircleSpan = styled.span`
  display: inline-block;
  position: absolute;
  top: 50%;
  z-index: 1;
  width: 22px;
  height: 22px;
  background: #ffffff;
  border-radius: 50%;
  ${(props) =>
    props.left
      ? "left: -11px; box-shadow: inset -2px 0 0 rgb(232, 222, 207);"
      : "right: -11px; box-shadow: inset 2px 0 0 rgb(232, 222, 207);"}
`;

const EventBox = styled(Grid)`
  background-image: url(${(props) => props.backgroundImage});
  background-size: 100% 100%;
  position: relative;
  background-repeat: no-repeat;
  margin: 0px !important;
  width: 100%;
  height: 25vh !important;
  border-top-left-radius: 12.5px;
  border-top-right-radius: 12.5px;
`;

const GradientOverlay = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 12.5px;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0) 50%,
    rgb(255 255 255 / 93%) 100%
  );
`;

export default function EventSummary() {
  const axios = useAxios();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { eventID } = useParams();

  const [eventDetails, setEventDetails] = useState({});

  useEffect(() => {
    axios({
      url: `/events/event-details?event_id=${eventID}`,
      method: "GET",
      customAuthToken: "9beaa95b-c59f-4ec9-bb60-5f4ee1986311",
    }).then((response) => {
      if (response.status) {
        setEventDetails(response.data);
      }
    });
  }, [axios, eventID]);

  const getButtonText = () => {
    if (state?.journey_status?.payment_status === "Pending")
      return "Payment Pending";
    if (state?.journey_status?.is_verified === false)
      return "Verification Pending";
    if (state?.journey_status?.send_consent === false) return "Consent Pending";
    if (state?.journey_status?.consent === false) return "Consent Pending";
    return false;
  };

  const handleClick = () => {
    const { journey_status, event_id, visitor_id, booking_id } = state;
    if (journey_status?.payment_status === "Pending") {
      navigate(`${pages.EventBooking.route}/${event_id}/${visitor_id}`);
    } else if (journey_status?.is_verified === false) {
      navigate(
        `${pages.Verification.route}?visitor_id=${visitor_id}&booking_id=${booking_id}&event_id=${event_id}`
      );
    } else if (journey_status?.consent === false) {
      navigate(
        `${pages.Consent.route}?booking_id=${booking_id}&visitorId=${visitor_id}`
      );
    }
  };

  const renderInfoItem = (label, value) => (
    <Grid item xs={12} sm={6}>
      <Typography
        variant="body1"
        style={{ color: "rgb(150 150 150)" }}
        fontWeight={900}
      >
        {label}
      </Typography>
      <Typography variant="h6" fontWeight={900}>
        {value}
      </Typography>
    </Grid>
  );

  return (
    <OuterBox>
      <OuterPaper
        sx={{ padding: { xs: "30px 0px 0px 0px", sm: "30px 80px 0px 80px" } }}
      >
        <div class="id-card-hook"></div>
        <Grid
          container
          flexDirection="column"
          padding={{ xs: "0px", sm: "0px", md: "0px" }}
          sx={{ height: "100%" }}
        >
          <Grid
            item
            container
            sx={{
              height: "100%",
              position: "relative",
              borderRadius: "12.5px",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              justifyContent: "center",
              // border: "2px solid #484848",
            }}
          >
            {/* <CircleSpan left />
            <CircleSpan /> */}

            <Grid item padding="20px">
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bolder",
                  "-webkit-text-stroke-width": ".5px",
                }}
              >
                Booking Summary
              </Typography>
            </Grid>

            <EventBox
              backgroundImage={
                eventDetails?.banner_image ||
                "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/3e9c86447ad14afb8b260fe48efe0eb0/1724237319019_image-cache.jpeg"
              }
              style={{
                position: "relative",
                backgroundRepeat: "no-repeat",
                margin: " 0px !important",
                width: "92.5%",
                height: "25vh !important",
                borderRadius: "25px",
              }}
            >
              {/* <img src={eventDetails?.banner_image} /> */}

              <GradientOverlay />
              <Grid
                item
                container
                position="absolute"
                bottom={0}
                sx={{ justifyContent: "space-between", padding: "20px 5%" }}
              >
                <Grid item>
                  <Typography
                    variant="h3"
                    fontWeight="bold"
                    sx={{ WebkitTextStrokeWidth: ".5px" }}
                  >
                    {eventDetails.event_name}
                  </Typography>
                  <Typography variant="h6" fontWeight="bold" color="#747474">
                    {convertDate(eventDetails.event_start_date / 1000)}{" "}
                    <PlaceOutlinedIcon
                      sx={{ verticalAlign: "bottom", color: "#3869db" }}
                    />
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    borderRadius: "12px",
                    background: "rgb(28 28 28/1)",
                    color: "white",
                    padding: "10px",
                  }}
                >
                  <Typography variant="body1" fontWeight="bold">
                    {state.payment_status === "Completed" ? (
                      <VerifiedIcon
                        sx={{
                          color: "green",
                          verticalAlign: "sub",
                          fontSize: "17.5px",
                        }}
                      />
                    ) : (
                      <NewReleasesIcon
                        sx={{
                          color: "red",
                          verticalAlign: "sub",
                          fontSize: "17.5px",
                        }}
                      />
                    )}
                    {"  "}
                    {eventDetails?.ticket_cost}
                  </Typography>
                  <Typography variant="body1" fontWeight="bold">
                    {state.payment_status}
                  </Typography>
                </Grid>
              </Grid>
            </EventBox>

            <Grid item sx={{ width: "100%" }} padding="30px">
              <Grid item container justifyContent="center">
                <Grid
                  item
                  container
                  xs={12}
                  sx={{
                    alignContent: "center",
                    justifyContent: "space-between",
                    textAlign: { xs: "center", sm: "start" },
                  }}
                >
                  <Grid item xs={12} sm={6}>
                    {renderInfoItem("Name", state?.visitor_info?.first_name)}
                    {renderInfoItem(
                      "Phone Number",
                      state?.visitor_info?.phone_number
                    )}
                    {renderInfoItem("Email ID", state?.visitor_info?.email_id)}
                  </Grid>

                  {Boolean(
                    state?.qr_link && !state?.journey_status?.send_consent
                  ) ? (
                    <Grid
                      item
                      sx={{ display: "flex", justifyContent: "center" }}
                      xs={12}
                      sm={6}
                    >
                      <img src={state.qr_link} className="QrCodeImage" />
                    </Grid>
                  ) : (
                    <Grid
                      item
                      container
                      xs={12}
                      sm={6}
                      sx={{ alignSelf: "center", justifyContent: "center" }}
                    >
                      {getButtonText() && (
                        <LoadingButton
                          onClick={handleClick}
                          variant="contained"
                          sx={{
                            width: "70%",
                            height: "70%",
                            background:
                              "conic-gradient(from 90deg at 3px 3px, rgba(0, 0, 0, 0) 90deg, rgb(31, 200, 127) 0deg) 0.45rem 0.45rem / calc(100% - 3px - 0.9rem) calc(100% - 3px - 0.9rem)",

                            color: "rgb(31, 200, 127)",
                            fontWeight: "bolder",
                            "-webkit-text-stroke-width": ".5px",
                            fontSize: "20px",
                            padding: "10px",
                            ":hover": {
                              background:
                                "conic-gradient(from 90deg at 3px 3px, rgba(0, 0, 0, 0) 90deg, rgb(31, 200, 127) 0deg) 0.75rem 0.75rem / calc(100% - 13px - 0.9rem) calc(100% - 10px - 0.9rem)",
                              boxShadow: "none",
                            },
                          }}
                        >
                          {getButtonText()}
                        </LoadingButton>
                      )}
                    </Grid>
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{ textAlign: { xs: "center", sm: "start" } }}
                >
                  {renderInfoItem("Booking ID", state?.booking_id)}
                  {renderInfoItem(
                    "Registration Date",
                    convertDate(state?.registered_at)
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </OuterPaper>
    </OuterBox>
  );
}
