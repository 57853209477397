export const eventBokingObject = {
  name: "",
  mobile: "",
  email: "",
  event: "",
  eventAmount: 0,
  subEvent: "",
  subEventAmount: 0,
  state: "",
  district: "",
  organization: "",
  gender: "",
  designation: "",
};
export function objectFromLocationData(data, eventObject) {
  return {
    name: data?.visitor_info?.first_name || data?.first_name,
    mobile:
      data?.phone_number ||
      data?.visitor_info?.phone_number ||
      sessionStorage.getItem("mobileNumber"),
    email:
      data?.email_id ||
      data?.visitor_info?.email_id ||
      sessionStorage?.getItem("email"),
    event: data?.event_id,
    eventAmount: eventObject?.ticket_cost,
    state: data?.state_id,
    district: data?.district_id,
    organization: data?.organization_name,
    designation: data?.designation,
    subEventAmount: 0,
    gender: data?.gender || data?.visitor_info?.gender,
  };
}
