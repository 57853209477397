import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";

import {
  TextField,
  SelectField,
  MandatoryNote,
  LoadingButton,
  CommonTextField,
} from "../../../../components";
import EventCard from "../EventCard";
import { useAxios } from "../../../../hooks";
import DocumentUpload from "../../DocumentUpload";
import { TiTicket } from "react-icons/ti";

function Form({ formik, eventID, eventObject, stateOptions, subEventOptions }) {
  const navigate = useNavigate();
  const axiosApi = useAxios({ baseURL: "mox_api_2" });

  const { state } = useLocation();

  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [districtOptions, setDistrictOptions] = useState([]);

  const handleInputChange = (e) => {
    const regex = /^[A-Za-z\s]*$/;

    const inputValue = e.target.value;

    if (regex.test(inputValue)) {
      formik.handleChange(e);
    }
  };

  const handleDownloadPDF = (link) => {
    const pdfLink = link;

    window.open(pdfLink, "_blank");
  };

  const handleSubEventSelect = (ID) => {
    const selectedEventId = ID;

    const selectedEvent = subEventOptions.find(
      (event) => event?._id === selectedEventId
    );

    formik.setFieldValue(
      "subEvent",
      formik.values.subEvent ? "" : selectedEvent?._id
    );

    formik.setFieldValue(
      "subEventAmount",
      formik.values.subEventAmount ? 0 : parseInt(selectedEvent?.price)
    );

    if (eventID && !formik.values.eventAmount) {
      formik.setFieldValue("eventAmount", parseInt(eventObject.ticket_cost));
    }
  };

  const handleStateChange = (event) => {
    setDistrictOptions([]);

    const selectedState = event.target.value;

    formik.setFieldValue("state", selectedState);

    if (eventID && !formik.values.eventAmount) {
      formik.setFieldValue("eventAmount", eventObject.ticket_cost);
    }

    formik.setFieldValue("district", "");

    fetchDistricts(selectedState);
  };

  const fetchDistricts = async (selectedState) => {
    try {
      const response = await axiosApi({
        url: `/booking/districts?state_id=${selectedState}`,
        method: "GET",
      });
      if (response.status) {
        const formattedDistricts = response.data.map((district) => ({
          id: district?.district_id,
          name: district?.district_name,
        }));

        setDistrictOptions(formattedDistricts);
      }
    } catch (error) {
      console.error("Error in fetching district list", error);
    }
  };

  useEffect(() => {
    if (formik.values.state) {
      fetchDistricts(formik.values.state);
    }

    console.log(state?.first_name, "state");
  }, []);

  return (
    <Fragment>
      <Grid container spacing={3} sx={{ textTransform: "capitalize" }}>
        {eventObject.event_name && (
          <Grid item xs={12} sm={12}>
            <EventCard
              eventObject={eventObject}
              handleDownloadPDF={handleDownloadPDF}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <TextField
            name="name"
            label={"Name"}
            formikProps={formik}
            disabled={Boolean(state?.first_name)}
          />
        </Grid>

        <SelectField
          label="Select Your Gender"
          name="gender"
          value={formik.values.gender}
          options={[
            { id: "Male", name: "Male" },
            { id: "Female", name: "Female" },
          ]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.district && formik.errors.district}
          required
        />

        <Grid mt={1} item xs={12} sm={6}>
          <TextField
            name="mobile"
            label={"Mobile No"}
            formikProps={formik}
            disabled
          />
        </Grid>

        <CommonTextField
          label="Enter Your Email"
          placeholder="Enter Your Email"
          name="email"
          type="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          required
        />

        <SelectField
          label="Choose Your State"
          value={formik.values.state}
          onChange={handleStateChange}
          options={stateOptions}
          error={formik.touched.state && Boolean(formik.errors.state)}
          required
        />

        <SelectField
          label="Choose Your District"
          name="district"
          value={formik.values.district}
          options={districtOptions}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.district && formik.errors.district}
          required
        />

        {subEventOptions.length > 0 && (
          <Fragment>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                mb={3}
                fontWeight={700}
                color={"rgb(215, 139, 0)"}
              >
                Select Event Badge
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              display={"flex"}
              width={0}
              sx={{
                border: " 1px solid rgb(232, 222, 207)",
                borderRadius: "12.5px",
                overflowY: "scroll",
                scrollbarWidth: "none",
                flexDirection: "row",
                padding: "10px 5px !important",
              }}
            >
              {subEventOptions.map((items, index) => {
                return (
                  <div
                    style={{
                      margin: "5px",
                      background: items.color,
                      padding: "10px 20px",
                      borderRadius: "12.5px",
                      boxShadow:
                        formik.values.subEvent === items._id &&
                        "rgb(49, 49, 48) 0px 0px 1px 2px",
                    }}
                    onClick={() => handleSubEventSelect(items?._id)}
                  >
                    <Typography sx={{ fontWeight: 900, textAlign: "start" }}>
                      {items.badge_name}
                    </Typography>

                    <Typography
                      sx={{
                        fontWeight: 900,
                        textAlign: "start",
                        color: "rgb(233 175 71)",
                      }}
                    >
                      Rs {items.price}
                    </Typography>
                  </div>
                );
              })}
            </Grid>
          </Fragment>
        )}

        <CommonTextField
          label="Enter Your organization's Name(Optional)"
          placeholder="Enter Your organization's Name(Optional)"
          name="organization"
          type="text"
          value={formik.values.organization}
          onChange={handleInputChange}
          onBlur={formik.handleBlur}
        />

        <CommonTextField
          label="Enter Your Designation(Optional)"
          placeholder="Enter Your Designation Name(Optional)"
          name="designation"
          type="text"
          value={formik.values.designation}
          onChange={handleInputChange}
          onBlur={formik.handleBlur}
        />

        {Boolean(formik.values.eventAmount || formik.values.subEventAmount) && (
          <Grid item container>
            <table
              style={{
                width: "100%",
                border: "1px solid rgb(232, 222, 207)",
                padding: "10px",
                borderRadius: "5px",
                position: "relative",
                boxShadow: "3px 3px 5px -2px rgb(232 196 144)",
              }}
            >
              <span
                class="__circle-left"
                style={{
                  display: "inline-block",
                  position: "absolute",
                  top: "45%",
                  zIndex: "1",
                  width: "22px",
                  height: "22px",
                  background: "#ffffff",
                  borderRadius: "50%",
                  left: "-11px",
                  boxShadow: "inset -2px 0 0 rgb(232, 222, 207)",
                }}
              ></span>
              <span
                class="__circle-right"
                style={{
                  display: "inline-block",
                  position: "absolute",
                  top: "40%",
                  zIndex: "1",
                  width: "22px",
                  height: "22px",
                  background: "#ffffff",
                  borderRadius: "50%",
                  right: "-11px",
                  boxShadow: "inset 2px 0 0 rgb(232, 222, 207)",
                }}
              ></span>
              <thead>
                <tr>
                  <td
                    style={{
                      fontSize: "large",
                      fontWeight: "600",
                      color: "black",
                    }}
                  >
                    Event Ticket
                  </td>
                  <td
                    style={{
                      fontSize: "large",
                      fontWeight: "600",
                      color: "black",
                    }}
                  ></td>
                </tr>
              </thead>
              {console.log(formik.values, "formik.valuesF")}
              <tbody>
                {Boolean(formik.values.eventAmount) && (
                  <tr>
                    <td>
                      <TiTicket
                        style={{ fontSize: "19px", verticalAlign: "top" }}
                      />{" "}
                      1&nbsp; &#10006; &nbsp;
                      {eventObject?.event_name}&nbsp; - &nbsp; ₹
                      {formik.values.eventAmount}
                    </td>
                    <td style={{ textAlign: "end" }}>
                      ₹{formik.values.eventAmount}
                    </td>
                  </tr>
                )}

                {Boolean(formik.values.subEventAmount) && (
                  <tr>
                    <td>
                      <TiTicket
                        style={{ fontSize: "19px", verticalAlign: "top" }}
                      />{" "}
                      1&nbsp; &#10006; &nbsp;{" "}
                      {
                        subEventOptions.filter((item) => {
                          console.log(
                            item._id,
                            formik.values.subEvent,
                            "ooops"
                          );
                          return item._id == formik.values.subEvent;
                        })[0]?.badge_name
                      }{" "}
                      &nbsp;- &nbsp;₹ {formik.values.subEventAmount}
                    </td>
                    <td style={{ textAlign: "end" }}>
                      ₹ {formik.values.subEventAmount}
                    </td>
                  </tr>
                )}

                <tr>
                  <td
                    style={{
                      fontSize: "large",
                      fontWeight: "700",
                      color: "black",
                    }}
                  >
                    Total
                  </td>
                  <td
                    style={{
                      fontSize: "large",
                      fontWeight: "700",
                      color: "black",
                      textAlign: "end",
                    }}
                  >
                    ₹{" "}
                    {parseInt(formik.values.eventAmount) +
                      (formik.values?.subEventAmount
                        ? formik.values?.subEventAmount
                        : 0)}
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
        )}
      </Grid>

      <MandatoryNote />

      <Grid item container spacing={{ sl: 2 }}>
        <Grid item xs={12} sl={6}>
          <LoadingButton
            loading={loading}
            onClick={formik.handleSubmit}
            disabled={uploadLoading || !formik.dirty || !formik.isValid}
            fullWidth
            backgroundColor="#009963"
            style={{ fontWeight: 800 }}
          >
            {`${
              formik.values.eventAmount + formik.values?.subEventAmount == 0
                ? "Take Next Step"
                : "Proceed to Pay"
            }`}

            {Boolean(
              formik.values.eventAmount + formik.values?.subEventAmount
            ) &&
              `: INR ${
                parseInt(formik.values.eventAmount) +
                (formik.values?.subEventAmount
                  ? formik.values?.subEventAmount
                  : 0)
              }`}
          </LoadingButton>
        </Grid>

        <Grid item xs={12} sl={6}>
          <LoadingButton
            loading={loading}
            onClick={() => navigate(-1)}
            fullWidth
            backgroundColor="#F5F0E5"
            style={{ fontWeight: 800, color: "black" }}
          >
            Back{" "}
          </LoadingButton>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default Form;
