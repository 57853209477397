import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import TextField from "./TextField";

const CommonTextField = ({
  label,
  placeholder,
  name,
  type,
  value,
  onChange,
  onBlur,
  error,
  required,
}) => {
  return (
    <Grid item xs={12} sm={6} mt={1}>
      <TextField
        fullWidth
        sx={{ marginBottom: "16px" }}
        variant="outlined"
        label={
          <Fragment>
            {label}
            {required && (
              <span
                style={{
                  color: "red",
                  float: "right",
                  paddingLeft: "5px",
                }}
              >
                *
              </span>
            )}
          </Fragment>
        }
        placeholder={placeholder}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
      />
    </Grid>
  );
};

export default CommonTextField;
