import React from "react";
import { FormControl, InputLabel, Select, MenuItem, Grid } from "@mui/material";

import { styled } from "@mui/system";

const CustomFormControl = styled(FormControl)(({ theme }) => ({
  "& .MuiOutlinedInput-root ": {
    "& fieldset": {
      borderColor: "rgb(232, 222, 207) !important",
      borderRadius: "12.5px",
    },
    "&:hover fieldset": {
      borderColor: "rgb(161, 130, 74)",
      borderRadius: "12.5px",
    },
    "&.Mui-focused fieldset": {
      borderColor: " rgb(161, 130, 74)",
      borderRadius: "12.5px",
    },
  },

  "&.MuiSelect-icon": {
    color: " rgb(161, 130, 74) !important",
  },

  "& .MuiOutlinedInput-notchedOutline ": {
    borderColor: "1px solid rgb(232, 222, 207)",
  },

  ".MuiInputLabel-root": {
    color: "rgb(161, 130, 74)",
  },
}));

const SelectField = ({
  label,
  name,
  value,
  options,
  onChange,
  onBlur,
  error,
}) => {
  return (
    <Grid item xs={12} sm={6}>
      <CustomFormControl
        fullWidth
        variant="outlined"
        sx={{ marginBottom: "16px" }}
        error={error && Boolean(error)}
      >
        <InputLabel
          id={`demo-simple-select-${name}`}
          sx={{ paddingRight: "20px" }}
        >
          {label}
          <span style={{ color: "red", float: "right", paddingLeft: "5px" }}>
            *
          </span>
        </InputLabel>
        <Select
          label={label}
          labelId={`demo-simple-select-${name}`}
          id={`demo-simple-${name}`}
          displayEmpty
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={options.length === 0}
        >
          {options.map((option) => (
            <MenuItem
              key={option.id}
              value={option.id}
              sx={{ justifyContent: "space-between" }}
            >
              <span>{option.name} </span>
            </MenuItem>
          ))}
        </Select>
      </CustomFormControl>
    </Grid>
  );
};

export default SelectField;
