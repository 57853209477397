import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";

import pages from "../../constants/pages";
import useAxios from "./../../hooks/useAxios";
import convertDateDayMonth from "../../utils/convertDateDayMonth";

const OuterBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // min-height: 75vh;
  width: [100%, 100%, 100%, 800px];
  // margin: 10% 0;
  padding: 0px 17px;
  box-sizing: border-box;
  background: #ffffff;
`;

const OuterPaper = styled(Paper)`
  width: 100%;
  // padding: 20px;
  min-height: 20vh;
  background: rgba(255, 255, 255, 0.5);
  align-content: center;
  border-radius: 15px;
`;

function EventList() {
  const axios = useAxios();
  const navigate = useNavigate();

  const [eventList, setEventList] = useState([]);

  useEffect(() => {
    axios({ url: "/events/open-events", disableRedirect: true }).then(
      (response) => {
        if (response.status) {
          setEventList(response.data);
        }
      }
    );
  }, []);

  function handleEvent(ID) {
    navigate(pages.appointment.route + ID);
  }
  return (
    <Fragment>
      <OuterBox>
        <OuterPaper>
          <Grid item>
            <Typography
              textAlign={"center"}
              fontWeight={900}
              variant="h2"
              mb={3}
              mt={4}
              sx={{
                "-webkit-text-stroke": "black",
                "-webkit-text-stroke-width": "1px",
              }}
            >
              Events List
            </Typography>
          </Grid>
          <Grid container item className="eventListOuterBox">
            {eventList.map((item) => {
              return (
                <Grid
                  item
                  className="eventBox"
                  onClick={() => handleEvent(item.event_id)}
                  sx={{
                    backgroundImage: `url(${
                      item.banner_image ||
                      "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/3e9c86447ad14afb8b260fe48efe0eb0/1724237319019_image-cache.jpeg"
                    })`,
                    backgroundSize: "100% 100%",
                    position: "relative",
                    backgroundRepeat: "no-repeat",
                    width: { xs: "100%", sl: "45%" },
                  }}
                >
                  <div className="eventListImg"></div>

                  <Grid
                    item
                    container
                    position={"absolute"}
                    bottom={0}
                    sx={{
                      justifyContent: "space-between",
                      padding: "20px 5%",
                    }}
                  >
                    <Grid item xs={6}>
                      <Typography
                        variant="h3"
                        fontWeight={"bolder"}
                        sx={{
                          fontSize: { xs: "1.25rem", md: "1.5rem" },
                          "-webkit-text-stroke-color": "black",
                          "-webkit-text-stroke-width": ".1px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {item.event_name}
                      </Typography>
                      <Typography
                        variant="h4"
                        fontWeight={700}
                        sx={{
                          color: "#3c3c3c",
                          marginTop: "5px",
                        }}
                      >
                        {convertDateDayMonth(item.event_start_date / 1000)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      sx={{
                        borderRadius: "12px",
                        background: " rgb(28 28 28/1)",
                        color: " white",
                        padding: " 10px",
                      }}
                      xs={6}
                    >
                      <Typography
                        variant="h4"
                        fontWeight={"bold"}
                        sx={{ fontSize: { xs: "1rem", md: "1.125rem" } }}
                      >
                        ₹ {item.ticket_cost}
                      </Typography>
                      <Typography
                        variant="h4"
                        fontWeight={"bold"}
                        sx={{ fontSize: { xs: ".9rem", md: "1.125rem" } }}
                      >
                        Starting From
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </OuterPaper>
      </OuterBox>
    </Fragment>
  );
}

export default EventList;
